import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function Home() {
  const { push, isReady } = useRouter()

  useEffect(() => {
    if (isReady) {
      push('/displays')
    }
  }, [isReady])
}
